import revive_payload_client_j0t5OHtkho from "/app/node_modules/.pnpm/nuxt@3.6.5_@parcel+watcher@2.3.0_@types+node@18.0.0_encoding@0.1.13_rollup@3.28.0_sass@1.63.6_g7nsthiun5owl2hxqv4qpotfoa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import router_0GTACdR3pp from "/app/node_modules/.pnpm/nuxt@3.6.5_@parcel+watcher@2.3.0_@types+node@18.0.0_encoding@0.1.13_rollup@3.28.0_sass@1.63.6_g7nsthiun5owl2hxqv4qpotfoa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import siteConfig_EpWavi2Sjb from "/app/node_modules/.pnpm/nuxt-site-config@1.0.10_rollup@3.28.0/node_modules/nuxt-site-config/dist/runtime/plugins/siteConfig.mjs";
import plugin_vue3_9SxxhQIzn8 from "/app/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@3.28.0_typescript@5.2.2_vue@3.3.4/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import unhead_ivoIe5lLFn from "/app/node_modules/.pnpm/nuxt@3.6.5_@parcel+watcher@2.3.0_@types+node@18.0.0_encoding@0.1.13_rollup@3.28.0_sass@1.63.6_g7nsthiun5owl2hxqv4qpotfoa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import prefetch_client_DQdEKEMJCx from "/app/node_modules/.pnpm/nuxt@3.6.5_@parcel+watcher@2.3.0_@types+node@18.0.0_encoding@0.1.13_rollup@3.28.0_sass@1.63.6_g7nsthiun5owl2hxqv4qpotfoa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_uqEIO53pxu from "/app/node_modules/.pnpm/nuxt-directus-seo@1.6.1_patch_hash=5p2csyov6nxauam3bjytwqzr2q_h3@1.8.1_rollup@3.28.0/node_modules/nuxt-directus-seo/dist/runtime/plugin.mjs";
import plugin_ivtZx1YUhU from "/app/node_modules/.pnpm/nuxt-live-editor@1.5.75_encoding@0.1.13_h3@1.8.1_postcss@8.4.28_rollup@3.28.0_typescript@5.2._zjvwqry5gootbsuy2y7xxur7lu/node_modules/nuxt-live-editor/dist/runtime/plugin.mjs";
import composition_0wUZoFe81e from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.12_rollup@3.28.0_vue-router@4.2.4_vue@3.3.4__vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_hgWCNsBqgq from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.0.0-beta.12_rollup@3.28.0_vue-router@4.2.4_vue@3.3.4__vue@3.3.4/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_N0CVRbrJAm from "/app/node_modules/.pnpm/nuxt-commons-ui@1.4.26_h3@1.8.1_rollup@3.28.0/node_modules/nuxt-commons-ui/dist/runtime/plugin.mjs";
import plugin_utYc1EILxo from "/app/node_modules/.pnpm/nuxt-directus@5.5.0_rollup@3.28.0/node_modules/nuxt-directus/dist/runtime/plugin.mjs";
import unocss_MzCDxu9LMj from "/app/.nuxt/unocss.mjs";
import plugin_lZVVtEnNKv from "/app/modules/hooks/runtime/plugin.ts";
import chunk_reload_client_7k9KPuqxrs from "/app/node_modules/.pnpm/nuxt@3.6.5_@parcel+watcher@2.3.0_@types+node@18.0.0_encoding@0.1.13_rollup@3.28.0_sass@1.63.6_g7nsthiun5owl2hxqv4qpotfoa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import aosjs_VksiWCg8zg from "/app/plugins/aosjs.ts";
import printjs_gaPusXOk8Z from "/app/plugins/printjs.ts";
import marqueejs_ztWKvUmUJU from "/app/plugins/marqueejs.ts";
import vue_json_pretty_mZKxDYC2fj from "/app/plugins/vue-json-pretty.ts";
import ackee_lDvODnWAvn from "/app/plugins/ackee.ts";
import datepickerjts_cTZJelV3Mx from "/app/plugins/datepickerjts.ts";
import global_2XP5sjpyNs from "/app/plugins/global.ts";
import otpInput_icRs7kzlCs from "/app/plugins/otpInput.ts";
import recaptcha_85gNSCNFUU from "/app/plugins/recaptcha.ts";
import select_tI36lTGDUc from "/app/plugins/select.ts";
import swiperjs_vRysfokWmD from "/app/plugins/swiperjs.ts";
export default [
  revive_payload_client_j0t5OHtkho,
  router_0GTACdR3pp,
  siteConfig_EpWavi2Sjb,
  plugin_vue3_9SxxhQIzn8,
  components_plugin_KR1HBZs4kY,
  unhead_ivoIe5lLFn,
  prefetch_client_DQdEKEMJCx,
  plugin_uqEIO53pxu,
  plugin_ivtZx1YUhU,
  composition_0wUZoFe81e,
  i18n_hgWCNsBqgq,
  plugin_N0CVRbrJAm,
  plugin_utYc1EILxo,
  unocss_MzCDxu9LMj,
  plugin_lZVVtEnNKv,
  chunk_reload_client_7k9KPuqxrs,
  aosjs_VksiWCg8zg,
  printjs_gaPusXOk8Z,
  marqueejs_ztWKvUmUJU,
  vue_json_pretty_mZKxDYC2fj,
  ackee_lDvODnWAvn,
  datepickerjts_cTZJelV3Mx,
  global_2XP5sjpyNs,
  otpInput_icRs7kzlCs,
  recaptcha_85gNSCNFUU,
  select_tI36lTGDUc,
  swiperjs_vRysfokWmD
]