export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"apple-mobile-web-app-status-bar-style","content":"black-translucent"},{"property":"og:url","content":"https://izion24.com.vn/trang/chinh-sach-bao-mat"},{"property":"og:image","content":"https://cms.izion24.com.vn/assets/1b79aac3-ac8b-4183-8b9c-c0919ce6f160.png"},{"property":"og:image:width","content":"684"},{"property":"og:image:height","content":"216"},{"property":"og:title","content":"IZIon24 - Bảo hiểm dễ dàng"},{"property":"fb:app:id","content":"751409503758987"},{"property":"og:image:type","content":"image/png"}],"link":[{"rel":"icon","href":"/favicon.ico","sizes":"any"},{"rel":"icon","type":"image/svg+xml","href":"/nuxt.svg"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"}],"style":[],"script":[],"noscript":[],"viewport":"width=device-width,initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const devPagesDir = null

export const devRootDir = null